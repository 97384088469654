// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-h-7-caorsp-0-ond-2-oka-js": () => import("./../../../src/pages/about-us-h7caorsp0ond2oka.js" /* webpackChunkName: "component---src-pages-about-us-h-7-caorsp-0-ond-2-oka-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-case-study-chrono-js": () => import("./../../../src/pages/case-study/chrono.js" /* webpackChunkName: "component---src-pages-case-study-chrono-js" */),
  "component---src-pages-case-study-fanclub-js": () => import("./../../../src/pages/case-study/fanclub.js" /* webpackChunkName: "component---src-pages-case-study-fanclub-js" */),
  "component---src-pages-case-study-flexnest-js": () => import("./../../../src/pages/case-study/flexnest.js" /* webpackChunkName: "component---src-pages-case-study-flexnest-js" */),
  "component---src-pages-case-study-mojapay-js": () => import("./../../../src/pages/case-study/mojapay.js" /* webpackChunkName: "component---src-pages-case-study-mojapay-js" */),
  "component---src-pages-case-study-pristeem-js": () => import("./../../../src/pages/case-study/pristeem.js" /* webpackChunkName: "component---src-pages-case-study-pristeem-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-startup-js": () => import("./../../../src/pages/startup.js" /* webpackChunkName: "component---src-pages-startup-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-our-service-js": () => import("./../../../src/templates/our-service.js" /* webpackChunkName: "component---src-templates-our-service-js" */)
}

